<script>
import dietPlanList from './dietPlanList'


export default {
  components: {dietPlanList},
  props: {},
  page: {title: 'Διατροφικά πλάνα'},
}

</script>
<template>
    <div id="dp-layout">
        <diet-plan-list></diet-plan-list>
    </div>
</template>
